import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Hero from 'src/components/LandingPage/Hero/Hero';
import BlogCard from 'src/components/NodeBlogCard/NodeBlogCard';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';

function BlogPostListingPage({ data, location }) {
  const posts = data.allSanityBlogPost.nodes;
  const [filterName, setFilterName] = useState('');

  const handleSetFilterName = (value) => {
    if (filterName !== '') {
      setFilterName('');
    } else {
      setFilterName(value);
    }
  };

  const {
    sanityBasicPage: {
      blog: { hero },
      title,
      pageSeo,
    },
  } = data;

  const seo = {
    title: pageSeo?.title ?? title,
    description: pageSeo?.description,
    image: pageSeo?.image?.asset?.url,
    location,
  };

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <Container fluid className="bg-shapes-banner px-0">
        <Container>
          <Hero hero={hero} />
          {/* Blog listing */}
          <Row>
            {/* Highlighted Blogs */}
            {filterName !== ''
              ? posts
                  .filter((p) => p?.category?.name === filterName)
                  .slice(0, 2)
                  .map((post) => (
                    <Col key={post.id} xs={12} lg={6} className="py-5">
                      <BlogCard post={post} handleSetFilterName={handleSetFilterName} />
                    </Col>
                  ))
              : posts.slice(0, 2).map((post) => (
                  <Col key={post.id} xs={12} lg={6} className="py-5">
                    <BlogCard post={post} handleSetFilterName={handleSetFilterName} />
                  </Col>
                ))}
            {/* Normal Blogs */}
            {filterName !== ''
              ? posts
                  .filter((p) => p?.category?.name === filterName)
                  .slice(2)
                  .map((post) => (
                    <Col key={post.id} xs={12} lg={6} xl={4} className="py-4">
                      <BlogCard post={post} handleSetFilterName={handleSetFilterName} />
                    </Col>
                  ))
              : posts.slice(2).map((post) => (
                  <Col key={post.id} xs={12} lg={6} xl={4} className="py-4">
                    <BlogCard post={post} handleSetFilterName={handleSetFilterName} />
                  </Col>
                ))}
          </Row>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    sanityBasicPage(id: { eq: $id }) {
      title
      pageSeo {
        ...getPageSeo
      }
      blog {
        hero {
          ...getHero
        }
      }
    }

    allSanityBlogPost(sort: { order: DESC, fields: publicationDate }) {
      nodes {
        ...getBlogPostAsCard
      }
    }
  }
`;

export default BlogPostListingPage;
